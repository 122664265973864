.approval-history-container {
    background: var(--Backgrounds-Surface, #fff);
    background-color: var(--Backgrounds-Surface, #fff);
    display: flex;
    max-width: 540px;
    flex-direction: column;
    overflow: hidden;
    justify-content: start;
    font: 14px Calibre-R, sans-serif;
  
  
  .history-wrap {
    display: flex;
    margin-top: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: start;
  }
  
  .history-item {
    border-bottom: 1px solid var(--Misc-Dividers-Divider, rgba(0, 63, 45, 0.15));
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    padding: 8px 4px 12px;
  }
  
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
  }
  
  .row-item {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    line-height: 1;
    justify-content: center;
  }
  
  .label {
    color: var(--Text-Dark-Text-Dark, #1a1a1a);
    font-weight: 600;
    align-self: stretch;
    flex: 1;
    flex-basis: 0%;
    margin: auto 0;
    line-height: 20px;
  }
  
  .date {
    color: var(--Text-Dark-Text-Dark-2nd, rgba(26, 26, 26, 0.65));
    text-align: right;
    font-weight: 400;
    align-self: stretch;
    margin: auto 0;
  }
  
  .author {
    color: var(--Text-Dark-Text-Dark, #1a1a1a);
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
  }
  
  .comments {
    color: var(--Text-Dark-Text-Dark-2nd, rgba(26, 26, 26, 0.65));
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }
}